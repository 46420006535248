import { Turbo } from '@hotwired/turbo-rails';

// Overrides confirm alert
// Selects turbo confirm dialog in _turbo_confirm.html.haml layout.

Turbo.config.forms.confirm = (message, element) => {
  let dialog = document.getElementById('turbo-confirm');

  dialog.querySelector('p').textContent = message;
  dialog.showModal();

  return new Promise((resolve, reject) => {
    dialog.addEventListener(
      'close',
      () => {
        resolve(dialog.returnValue == 'confirm');
      },
      { once: true }
    );
  });
};
